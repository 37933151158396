import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { DesktopContext } from "../../App";
import { CompactContext } from "../../App";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import StyledPopover from "../popover/StyledPopover";
import IconPopoverLink from "../popover/IconPopoverLink";
import MoreInfoPopoverLink from "../popover/MoreInfoPopoverLink";

export default function SingleCheckboxBox({
  checkboxes,
  errors,
  label,
  popoverContent,
}) {
  SingleCheckboxBox.propTypes = {
    checkboxes: PropTypes.element.isRequired,
    errors: PropTypes.object,
    label: PropTypes.string.isRequired,
    popoverContent: PropTypes.string,
  };

  //--- STATES -----------------------//
  const [anchorEl, setAnchorEl] = useState(null);

  //--- HANDLERS ---------------------//
  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  if (errors && errors.message) {
    console.log(errors.message);
  }

  const desktop = useContext(DesktopContext);
  const compact = useContext(CompactContext);

  const [checkboxError, setCheckboxError] = useState("");

  var rightCol = 12;
  if (desktop) {
    rightCol = 6;
  }

  useEffect(() => {
    if (errors !== undefined) {
      setCheckboxError(errors.message);
    } else if (errors === undefined) {
      setCheckboxError("");
    }
  }, [errors]);

  return (
    <>
      {desktop ? (
        <Grid container spacing={0}>
          <Grid item xs={rightCol} sx={{ pt: 3, pb: 1, pr: 2 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                paddingRight: "16px",
                marginLeft: "8px",
              }}
            >
              <label>
                <Typography
                  sx={{
                    color: "#4F145B !important",
                    fontWeight: "500 !important",
                    fontSize: "1rem !important",
                    lineHeight: "1.25 !important",
                    mb: "0px !important",
                    //ml: "0.5rem !important",
                  }}
                  dangerouslySetInnerHTML={{ __html: label }}
                />
              </label>
              {popoverContent && (
                <MoreInfoPopoverLink
                  handleOpenPopover={handleOpenPopover}
                  handleClosePopover={handleClosePopover}
                />
              )}
            </div>
          </Grid>
          <Grid item xs={rightCol} sx={{ pt: 2, pb: 1 }}>
            <FormGroup sx={{ marginLeft: "11px !important" }}>
              <FormControlLabel control={checkboxes} />
            </FormGroup>
            {checkboxError && (
              <Typography
                sx={{
                  mt: "-4px !important",
                  mb: "0 !important",
                  ml: "3px !important",
                  mr: "3px !important",
                  fontSize: "0.9rem !important",
                  padding: "8px !important",
                  fontWeight: "500 !important",
                  color: "#ffffff !important",
                  lineHeight: "normal !important",
                  backgroundColor: "#A00202 !important",
                }}
              >
                {checkboxError}
              </Typography>
            )}
          </Grid>
        </Grid>
      ) : (
        <>
          <FormGroup sx={{ marginLeft: "11px !important" }}>
            <Grid container spacing={0}>
              <Grid item xs={11} sx={{ pt: 0 }}>
                <FormControlLabel
                  control={checkboxes}
                  label={
                    <Typography
                      sx={{
                        fontWeight: compact ? "400" : "500",
                        fontSize: "0.85rem !important",
                        lineHeight: compact ? "1.25 !important" : 1.5,
                        mb: compact ? "0px !important" : "0px !important",
                        ml: "-1rem !important",
                      }}
                      dangerouslySetInnerHTML={{ __html: label }}
                    />
                  }
                  sx={{
                    color: "#4F145B !important",
                    fontWeight: "500",
                    fontSize: "0.85rem !important",
                    lineHeight: "1 !important",
                    mb: "0 !important",
                  }}
                />{" "}
              </Grid>
              <Grid item xs={1} sx={{ pt: 0, textAlign: "center" }}>
                {popoverContent && (
                  <IconPopoverLink
                    handleOpenPopover={handleOpenPopover}
                    handleClosePopover={handleClosePopover}
                  />
                )}
              </Grid>
            </Grid>
          </FormGroup>

          {checkboxError && (
            <Typography
              sx={{
                mt: "-4px !important",
                mb: "0 !important",
                ml: "3px !important",
                mr: "3px !important",
                fontSize: "0.9rem !important",
                padding: "8px !important",
                fontWeight: "500 !important",
                color: "#ffffff !important",
                lineHeight: "normal !important",
                backgroundColor: "#A00202 !important",
              }}
            >
              {checkboxError}
            </Typography>
          )}
        </>
      )}
      <StyledPopover
        open={Boolean(anchorEl)} // Ensures `open` is true when `anchorEl` is not null, and false otherwise
        anchorEl={anchorEl}
        handleClosePopover={handleClosePopover}
        popoverContent={popoverContent}
      />
    </>
  );
}
