import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { CompactContext } from "../../App";

function ControlledCheckbox({
  register,
  registerWhat,
  checkboxLabel,
  checkboxValue,
  checkboxID,
  defaultChecked,
  error,
}) {
  const [hiddenState, setHiddenState] = useState(true);
  const compact = useContext(CompactContext);
  useEffect(() => {
    if (checkboxValue === "Hidden") {
      setHiddenState(false);
    }
  }, [checkboxValue]);

  return (
    <>
      {hiddenState && (
        <FormControlLabel
          {...register(registerWhat)}
          control={
            <Checkbox
              defaultChecked={defaultChecked}
              id={checkboxID}
              sx={{ "& .MuiSvgIcon-root": { fontSize: compact ? 22 : 28 } }}
              error={error ? "true" : "false"}
            />
          }
          label={
            <Typography
              sx={{
                fontSize: "1rem !important",
                lineHeight: "1.5 !important",
                mb: "0px !important",
                display: "none",
              }}
            >
              {checkboxLabel}
            </Typography>
          }
          value={checkboxValue}
        />
      )}
    </>
  );
}

ControlledCheckbox.propTypes = {
  register: PropTypes.func.isRequired,
  registerWhat: PropTypes.string,
  checkboxLabel: PropTypes.string,
  checkboxValue: PropTypes.string,
  checkboxID: PropTypes.string,
  defaultChecked: PropTypes.bool,
  error: PropTypes.bool,
};

export default ControlledCheckbox;
